import {
  filter,
  find,
  keyBy,
  sortBy,
  uniqBy,
  isEmpty,
} from 'lodash';

import {
  getStates,
  updateGlobal,
  updateCitiesByCountry,
  updateStatesByCountry,
  updateCitiesByState,
} from '../store';
import * as apiClient from '../api-client';
import { fromCity, fromState } from '../api-transforms';
import { gcc_country_codes as gccCountryCodes } from '../enums';
import * as reasonCodeAction from './reason-code';

import getErrorFromBEResponse from '../helpers/getErrorFromBEResponse';
import filterEnumListByMerchants from '../helpers/filterEnumListByMerchants';
import promiseCacher from '../helpers/promiseCacher';
import { fetchUserPref, saveUserPref } from '../helpers/userPrefLocalStorage';

import { error as toastError } from './toast';
import { showLoadingState, hideLoadingState } from './loading';
import { getLocationDetails, getLocationOptions } from './location';
import { getCarrierList } from './carrier';

export const setState = updateGlobal;

/**
 * @param {{merchants: string[]|null}[]} enumList
 */
function filterAccountLevelEnums(enumList) {
  return enumList.filter(({ merchants }) => !merchants);
}

function filterLocationsByIds(locations, locationIds) {
  if (!locationIds?.length) return locations;
  return locations.filter(({ locationId }) => locationIds.includes(locationId));
}

function filterLocationsByMerchant(locations = [], merchantIds = [], allowedLocations = []) {
  let filteredLocations = [];
  filteredLocations = filterLocationsByIds(locations, allowedLocations);
  // for multi-merchant tenants, allow locations that have exactly one merchant
  if (merchantIds.length > 1) {
    filteredLocations = locations.filter((location) => location.merchants?.length === 1);
  }
  return filteredLocations;
}

export const preloadGccCountries = async () => {
  // pre-load GCC cities and states
  const allCitiesPerCountry = await Promise.all(gccCountryCodes.map(async (country) => {
    let cities = [];
    let states = [];
    try {
      [cities, states] = await apiClient.getCitiesWithState({ country });
    } catch (err) {
      console.error(err);
      toastError(`Could not fetch Cities for ${country}`);
    }

    return { country, cities, states };
  }));
  const {
    citiesByCountry = {},
    statesByCountry = {},
    citiesByState = {},
  } = allCitiesPerCountry
    .filter(({ cities = [], states = [] }) => (
      cities.length > 0 || states.length > 0
    )).reduce((acc, { country, cities, states }) => {
      const cityList = uniqBy(cities.map(fromCity), 'value');
      const stateList = uniqBy(states.map(fromState), 'value');
      // cities have unique code within a country, so it is ok to merge city and states
      // into one data structure.
      const citiesByStateList = stateList.map(({ label, value }) => ({
        label,
        value,
        cities: sortBy(filter(cities, { state_code: value }).map(fromCity), 'label'),
      }));
      return {
        ...acc,
        citiesByCountry: {
          ...(acc?.citiesByCountry || {}),
          [country]: sortBy(cityList, 'label'),
        },
        statesByCountry: {
          ...(acc?.statesByCountry || {}),
          [country]: sortBy(stateList, 'label'),
        },
        citiesByState: {
          ...(acc?.citiesByState || {}),
          [country]: sortBy(citiesByStateList, 'label'),
        },
      };
    }, {
      citiesByCountry: {},
      statesByCountry: {},
      citiesByState: {},
    });
  citiesByCountry.all = sortBy(Object
    .values(citiesByCountry)
    .reduce((acc, cityGroup) => [...acc, ...cityGroup], []), 'label');
  statesByCountry.all = sortBy(Object
    .values(statesByCountry)
    .reduce((acc, stateGroup) => [...acc, ...stateGroup], []), 'label');
  citiesByState.all = sortBy(Object
    .values(citiesByState)
    .reduce((acc, cityByStateGroup) => [...acc, ...cityByStateGroup], []), 'label');
  statesByCountry.perCity = allCitiesPerCountry
    .filter(({ cities = [] }) => Boolean(cities.length))
    .reduce((acc, { cities }) => ({
      ...acc,
      ...(cities.reduce((cityAcc, city) => {
        const { value: cityCode } = fromCity(city);
        const stateData = fromState(city);
        return {
          ...cityAcc,
          [cityCode]: stateData,
        };
      }, {})),
    }), {});

  updateCitiesByCountry(citiesByCountry);
  updateStatesByCountry(statesByCountry);
  updateCitiesByState(citiesByState);
};

export const getUserDefinedEnums = async ({
  props = [
    'tenantSettings',
    'carrierMetadata',
    'deliveryTypes',
    'customAttributes',
    'merchants',
  ],
  bustCache = false,
} = {}) => {
  showLoadingState();
  const subscription = await apiClient.getSubscription();

  if (subscription.active === false) {
    updateGlobal({ tenantSettings: subscription });
    hideLoadingState();
    return;
  }

  // make sure getUserDefinedEnums() is the first call
  const blockingFetchesPromise = Promise.all([
    apiClient.getUserDefinedEnums({ props, bustCache }),
    getLocationOptions(),
  ]);
  const nonBlockingFetchesPromise = Promise.all([
    preloadGccCountries(),
    reasonCodeAction.getAllReasonCodes({ bustCache }),
    getCarrierList(),
  ]);
  const [userDefinedEnums, { data: updatedLocations }] = await blockingFetchesPromise;

  const {
    auth: {
      canAccessAllMerchants,
      locations: permittedLocations,
    },
  } = getStates();
  const {
    carrierMetadata = [],
    tenantSettings,
    merchants, // accessible merchants
    deliveryTypes: allDeliveryTypes,
    customAttributes: allCustomAttributes,
  } = userDefinedEnums;
  const merchantIds = merchants.map(({ id }) => id);

  const savedLocationId = fetchUserPref('global-selectedLocationId');

  let selectedLocation = find(filterLocationsByMerchant(
    updatedLocations,
    merchantIds,
    permittedLocations,
  ), { locationId: savedLocationId });

  // Fetch the assigned location if not in the default list
  if (isEmpty(selectedLocation)) {
    const { data: { locations = [] } } = await getLocationDetails({
      locationIds: [savedLocationId],
    });
    selectedLocation = find(filterLocationsByMerchant(
      locations,
      merchantIds,
      permittedLocations,
    ), { locationId: savedLocationId })
    || locations[0];
  }

  // handle case where user loses access to a location
  const selectedLocationId = selectedLocation?.locationId;

  const selectedMerchantIds = selectedLocation?.merchants
    // handle merchants being account level for single-merchant tenants
    || [merchants[0]?.id];
  const selectedMerchants = merchants.filter(({ id }) => selectedMerchantIds.includes(id));

  // account level access flags
  const viewHasOnlyOneMerchant = merchants.length === 1;
  const firstMerchant = merchants?.[0];
  const canAccessAccountNotificationSettings = (
    canAccessAllMerchants
    && (!viewHasOnlyOneMerchant || !firstMerchant?.notificationSettingsOverrideEnabled)
  );
  const canAccessAccountRoutingRules = (
    canAccessAllMerchants
    && (!viewHasOnlyOneMerchant || !firstMerchant?.routingRulesOverrideEnabled)
  );
  const canAccessAccountServiceLevels = (
    canAccessAllMerchants
    && (!viewHasOnlyOneMerchant || !firstMerchant?.serviceLevelRulesOverrideEnabled)
  );

  const deliveryTypesForMerchants = filterEnumListByMerchants(
    allDeliveryTypes,
    selectedMerchantIds,
  );
  const deliveryTypesForAccount = filterAccountLevelEnums(allDeliveryTypes);
  const customAttributesForMerchants = filterEnumListByMerchants(
    allCustomAttributes,
    selectedMerchantIds,
  );
  const customAttributesForAccount = filterAccountLevelEnums(allCustomAttributes);

  setState({
    globalEnumsLoaded: true,
    tenantSettings: {
      ...tenantSettings,
      ...subscription,
    },
    selectedLocation,
    selectedLocationId,

    merchants,
    merchantIds,
    selectedMerchants,
    selectedMerchantIds,

    // account level access flags
    canAccessAccountNotificationSettings,
    canAccessAccountRoutingRules,
    canAccessAccountServiceLevels,

    carrierMetadata,

    deliveryTypes: deliveryTypesForMerchants,
    deliveryTypesForAccount,
    allDeliveryTypes,

    customAttributes: customAttributesForMerchants,
    customAttributesForAccount,
    allCustomAttributes,
  });
  await nonBlockingFetchesPromise;
  hideLoadingState();
};

export const setCitiesForCountry = (country, cities) => {
  updateCitiesByCountry({
    [country]: sortBy(uniqBy(cities, 'value'), 'label'),
  });
};

export const setCitiesByState = (country, state, cities) => {
  const citiesByStateList = state.map(({ label, value }) => ({
    label,
    value,
    cities: sortBy(filter(cities, { state_code: value }).map(fromCity), 'label'),
  }));
  updateCitiesByState({
    [country]: citiesByStateList,
  });
};

export const setCitiesForState = (country, state, cities) => {
  updateCitiesByState({
    [country]: {
      [state]: sortBy(uniqBy(cities, 'value'), 'label'),
    },
  });
};
export const setStatesForCountry = (country, states) => {
  updateStatesByCountry({
    [country]: sortBy(uniqBy(states, 'value'), 'label'),
  });
};

export const setStatesPerCity = async (statesPerCity) => {
  const { states: { perCity: currentStatesPerCity = {} } = {} } = getStates();
  updateStatesByCountry({
    perCity: {
      ...currentStatesPerCity,
      ...statesPerCity,
    },
  });
};

export const fetchCitiesForCountry = promiseCacher(
  async (country) => {
    let cityList = [];
    let stateList = [];
    let citiesPerState = [];

    try {
      const [cities, states] = await Promise.all([
        apiClient.getCityList({ country }),
        apiClient.getStateList({ country }),
      ]);
      cityList = sortBy(uniqBy(cities.map(fromCity), 'value'), 'label');
      stateList = sortBy(uniqBy(states.map(fromState), 'value'), 'label');
      citiesPerState = stateList.map(({ label, value }) => ({
        cities: sortBy(filter(cities, { state_code: value }).map(fromCity), 'label'),
        label,
        value,
      }));
      setCitiesForCountry(country, cityList);
      setCitiesByState(country, citiesPerState);
      setStatesForCountry(country, stateList);
    } catch (err) {
      console.error(err);
    }
    return {
      cityList,
      stateList,
      citiesPerState,
    };
  },
);

export const selectLocation = (selectedLocationId) => {
  const {
    global: {
      merchants,
    },
    cachedEntities: {
      locations = [],
    },
  } = getStates();
  const selectedLocation = find(locations, { locationId: selectedLocationId });

  const selectedMerchantIds = selectedLocation?.merchants
    // handle merchants being account level for single-merchant tenants
    || [merchants[0]?.id];
  const selectedMerchants = merchants.filter(({ id }) => selectedMerchantIds.includes(id));

  saveUserPref('global-selectedLocationId', selectedLocationId);
  setState({
    selectedLocation,
    selectedLocationId,
    selectedMerchants,
    selectedMerchantIds,
  });
};

export const fetchAddressModel = async () => {
  showLoadingState();
  try {
    const addressModels = (await apiClient.getAddressModels()) || [];
    const addressModelByCountry = keyBy(addressModels, 'country');
    updateGlobal({ addressModelByCountry });
    hideLoadingState();
    return addressModelByCountry;
  } catch (err) {
    const errorMessage = getErrorFromBEResponse(err);
    toastError(errorMessage);
    hideLoadingState();
  }
  return null;
};

export const getNavDrawerOpen = () => {
  let navDrawerOpen = null;
  try {
    navDrawerOpen = localStorage.getItem('navDrawerOpen');
  } catch (err) {
    // safari private mode
  }

  return setState({
    navDrawerOpen: navDrawerOpen === null
    || navDrawerOpen === 'true',
  });
};

export const setNavDrawerOpen = (navDrawerOpen) => {
  try {
    localStorage.setItem('navDrawerOpen', navDrawerOpen);
  } catch (err) {
    // safari private mode
  }

  setState({ navDrawerOpen });
};
